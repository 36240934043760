import { Dispatch } from "redux"
import { EditorState, SelectedObjectType } from "store/editor/types"
import { ARContent, GroupObject } from "../data"
import { useEffect, useState } from "react"
import { addSelectedArContent, changeGroupTransform, changeSelectedArContent, clearSelectedObjects } from "store/actions"
import { createOrUpdateARContent } from "apis/arContent"

export const getInitGroupObject = (): GroupObject => ({
    position: {
        x: 0,
        y: 0,
        z: 0
    },
    rotation: {
        x: 0,
        y: 0,
        z: 0
    },
    scale: {
        x: 0,
        y: 0,
        z: 0
    },
    isCommited: false,
})

const updateContentFromGroup = (
    contents: ARContent[],
    originalContents: ARContent[],
    objectId: string,
    transform: GroupObject,
) => {
    const contentIdx = contents.findIndex(content => content._id === objectId)
    const ogContentIdx = originalContents.findIndex(content => content._id === objectId)
    if (contentIdx === -1 || ogContentIdx === -1) return
    const content = {...contents[contentIdx]}
    const ogContent = originalContents[ogContentIdx]
    content.position = {
        x: ogContent.position.x + transform.position.x,
        y: ogContent.position.y + transform.position.y,
        z: ogContent.position.z + transform.position.z,
    }
    content.rotation = {
        x: ogContent.rotation.x + transform.rotation.x,
        y: ogContent.rotation.y + transform.rotation.y,
        z: ogContent.rotation.z + transform.rotation.z,
    }
    content.scale = {
        x: ogContent.scale.x + transform.scale.x,
        y: ogContent.scale.y + transform.scale.y,
        z: ogContent.scale.z + transform.scale.z,
    }
    contents[contentIdx] = content
    if (transform.isCommited) {
        createOrUpdateARContent(content)
    }
}

const useObjectSelectProvider = (
    editorState: EditorState,
    dispatch: Dispatch,
    arContents: ARContent[],
    onGroupTerminate: (newArContents: ARContent[]) => void,
) => {
    const [groupContents, setGroupContents] = useState<ARContent[] | null>(null)

    const initGroup = () => {
        setGroupContents([...arContents])
        dispatch(changeGroupTransform(getInitGroupObject()))
    }

    const updateArContentsFromGroup = () => {
        if (groupContents === null || editorState.groupTransform === null) return
        const newGroupContents = [...groupContents]
        editorState.selectedObjects.forEach(object => {
            updateContentFromGroup(newGroupContents, arContents, object.id, editorState.groupTransform!)
        })
        setGroupContents(newGroupContents)
    }

    const terminateGroup = () => {
        if (groupContents === null) return
        onGroupTerminate(groupContents)
        setGroupContents(null)
        dispatch(changeGroupTransform(null))
    }

    useEffect(() => {
        if (editorState.selectedObjects.length > 1 && groupContents === null) {
            initGroup()
        } else if (editorState.selectedObjects.length <= 1 && groupContents !== null) {
            terminateGroup()
        }
    }, [editorState.selectedObjects])

    useEffect(() => {
        if (editorState.groupTransform !== null) {
            updateArContentsFromGroup()
        }
    }, [editorState.groupTransform])

    return {
        groupContents,
    }
}

const getUpdatedGroupFromArContent = (initTransform: GroupObject, arContent: ARContent): GroupObject => {
    const newTransform = { ...initTransform }
    newTransform.position = {
        x: arContent.position.x - initTransform.position.x,
        y: arContent.position.y - initTransform.position.y,
        z: arContent.position.z - initTransform.position.z,
    }
    newTransform.rotation = {
        x: arContent.rotation.x - initTransform.rotation.x,
        y: arContent.rotation.y - initTransform.rotation.y,
        z: arContent.rotation.z - initTransform.rotation.z,
    }
    newTransform.scale = {
        x: arContent.scale.x - initTransform.scale.x,
        y: arContent.scale.y - initTransform.scale.y,
        z: arContent.scale.z - initTransform.scale.z,
    }
    return newTransform
}

// TODO: use this hook to do all ar content logic and remove props drilling
const useObjectSelect = (
    editorState: EditorState,
    dispatch: Dispatch,
    arContents: ARContent[],
) => {
    const [initTransform, setInitTransform] = useState<GroupObject | null>(null)
    const [parentId, setParentId] = useState<string | null>(null)

    const isGroupSelection = editorState.selectedObjects.length > 1
    const isAnythingSelected = editorState.selectedObjects.length > 0
    const groupTransform = editorState.groupTransform
    const selectedObjects = editorState.selectedObjects

    const initStateFromArContent = () => {
        if (!isGroupSelection) return
        const selectedParent = arContents.find(content => content._id === selectedObjects[0].id)
        if (!selectedParent) return
        setParentId(selectedParent._id!)
        setInitTransform({
            position: selectedParent.position,
            rotation: selectedParent.rotation,
            scale: selectedParent.scale,
        })

    }

    const terminateState = () => {
        setInitTransform(null)
        setParentId(null)
    }

    const updateGroupFromArContent = (content: ARContent, isCommited = false) => {
        if (!isGroupSelection || content._id !== parentId || initTransform === null) return
        const newGroup = getUpdatedGroupFromArContent(initTransform, content)
        newGroup.isCommited = isCommited
        dispatch(changeGroupTransform(newGroup))
    }

    const updateGroup = (newGroup: GroupObject, isCommited = false) => {
        if (!isGroupSelection) return
        newGroup.isCommited = isCommited
        dispatch(changeGroupTransform(newGroup))
    }

    useEffect(() => {
        if (isGroupSelection && parentId === null) {
            initStateFromArContent()
        } else if (!isGroupSelection) {
            terminateState()
        }
    }, [isGroupSelection])

    const selectObject = (objectId: string, objectType = SelectedObjectType.ArContent) => {
        if (objectType === SelectedObjectType.ArContent) {
            dispatch(changeSelectedArContent(objectId))
        }
    }

    const addObjectToSelect = (objectId: string, objectType = SelectedObjectType.ArContent) => {
        if (isAnythingSelected && objectType !== editorState.selectedObjectType) return
        if (objectType === SelectedObjectType.ArContent) {
            dispatch(addSelectedArContent(objectId))
        }
    }

    const clearSelection = () => {
        dispatch(clearSelectedObjects())
    }

    return {
        group: groupTransform,
        updateGroup,
        selectObject,
        addObjectToSelect,
        clearSelection,
        updateGroupFromArContent,
    }
}

export { useObjectSelectProvider, useObjectSelect }
